body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #0c0d10;
    font-family: 'Inter';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype'); /* Include other formats if needed */
}

@font-face {
    font-family: 'Inter-Light';
    src: url('./assets/fonts/Inter-Light.ttf') format('truetype'); /* Include other formats if needed */
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('./assets/fonts/Inter-Medium.ttf') format('truetype'); /* Include other formats if needed */
}

@font-face {
    font-family: 'Inter-Thin';
    src: url('./assets/fonts/Inter-Thin.ttf') format('truetype'); /* Include other formats if needed */
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype'); /* Include other formats if needed */
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('./assets/fonts/Inter-Bold.ttf') format('truetype'); /* Include other formats if needed */
}
